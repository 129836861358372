import {
  ENVIRONMENT,
  GIT_VERSION,
  IS_DATADOG_ENABLED,
} from "gatsby-env-variables"

import { datadogRum } from "@datadog/browser-rum"

const init = () => {
  if (!IS_DATADOG_ENABLED) return

  const variables = {
    development: {
      proxy: "https://proxy.datadog.dev.aws.cloud.ihf:3844",
      clientToken: "JN6-Garantidos-browser",
      applicationId: "dea7ec4d-d24e-4378-b5e6-3931f6d8a56c",
      sessionSampleRate: 100,
    },
    staging: {
      proxy: "https://proxy.datadog.dev.aws.cloud.ihf:3844",
      clientToken: "JN6-Garantidos-browser",
      applicationId: "dea7ec4d-d24e-4378-b5e6-3931f6d8a56c",
      sessionSampleRate: 100,
    },
    production: {
      proxy: "",
      clientToken: "pub1660d782982a7d8e67cf6aff5ce09e00",
      applicationId: "90be4973-006b-434a-960a-cdb577d41719",
      sessionSampleRate: 5,
    },
  }

  const proxyVar = variables[ENVIRONMENT].proxy
  const clientToken = variables[ENVIRONMENT].clientToken
  const applicationId = variables[ENVIRONMENT].applicationId
  const sessionSampleRate = variables[ENVIRONMENT].sessionSampleRate

  const proxy = {
    ...(proxyVar ? { proxy: proxyVar } : {}),
  }

  const version = {
    ...(ENVIRONMENT === "production" ? { version: GIT_VERSION } : {}),
  }

  datadogRum.init({
    clientToken,
    applicationId,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.com",
    service: "JN6-Garantidos",
    env: `${ENVIRONMENT}`,
    ...proxy,
    ...version,
    sessionSampleRate,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    actionNameAttribute: "datadog-action",
  })
}

export default {
  init,
}
