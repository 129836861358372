const setItem = (key, value) => {
  if (!(window && window.sessionStorage)) return
  if (typeof value === "object") value = JSON.stringify(value)
  sessionStorage.setItem(key, value)
}

const getItem = (key) => {
  if (!(window && window.sessionStorage)) return null
  return sessionStorage.getItem(key)
}

const clearStorage = (ignoreItens = []) => {
  if (!(window && window.sessionStorage)) return
  Object.keys(sessionStorage).forEach((key) => {
    if (![...ignoreItens, "cookie-bar-styles"].includes(key))
      sessionStorage.removeItem(key)
  })
}

const setCpfInStorage = (cpfData) => {
  const { cpf = "", hashedCpf = "" } = cpfData
  setItem("cpfData", { cpf, hashedCpf })
}

const getCpfFromStorage = () => {
  return getItem("cpfData")
}

const getIsCookieAcceptedFromStorage = () => {
  if (!(window && window.localStorage)) return null
  return localStorage.getItem("storage-isCookieAccepted")
}

export {
  setItem,
  getItem,
  clearStorage,
  setCpfInStorage,
  getCpfFromStorage,
  getIsCookieAcceptedFromStorage,
}
