import { OTP_SCRIPT, tokens } from "gatsby-env-variables"

import { saveCookieBarStylesToStorage } from "@garantidos/utils/cookieBar"
import datadog from "@garantidos/utils/js/datadog"
import { trackDefaultParameters } from "@garantidos/utils/tracking"
import appTrackers from "@garantidos/utils/tracking/appTrackers"

import configure from "@mobi/ds/configure"
import analytics from "@mobi/libraries/analytics"
import addScript from "@mobi/utils/browser/addScript"

import "styles/index.scss"

export const wrapRootElement = ({ element }) => element

configure({
  originFonts: "extern",
  fonts: {
    ItauDisplay: [900],
    ItauText: [400, 700]
  },
  setStyleVariablesInHTML: false
})

Promise.resolve(import("@garantidos/utils/bootstrap"))

if (!window.analyticsData) {
  window.analyticsData = {}
}

analytics.setup({
  segmentId: tokens.SEGMENT,
  GTMId: tokens.GTMId,
  trackings: appTrackers,
  isSDKTrackings: true
})

trackDefaultParameters({
  ga4: {
    business: "ncc",
    environment: "nao-logado",
    businessChannel: "web-itau-pf-pj-e-ncc-e-nl"
  }
})

datadog.init()
addScript(OTP_SCRIPT)

export const onRouteUpdate = () => {
  saveCookieBarStylesToStorage()
}
